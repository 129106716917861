import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FeauturesSharedModule } from '../features-shared.module';
import { LoginPageComponent } from './components/pages/login-page.component';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';

export const ROUTES: Routes = [
  {
    path: 'login',
    component: LoginPageComponent,
    canActivate: [],
  },
];

@NgModule({
  declarations: [LoginPageComponent],
  imports: [RouterModule.forRoot(ROUTES), FeauturesSharedModule, PasswordModule, InputTextModule],
  providers: [],
})
export class LoginModule {}
