import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'renovars-new-folder-dialog',
  template: `
    <fullstack-input-box [(ngModel)]="folderName" [options]="{ label: 'Nome file' }"></fullstack-input-box>
    <div class="flex align-items-end justify-content-end mt-4">
      <button pButton (click)="onSave()" class="p-button-sm me-4">Salva</button>
    </div>
  `,
  styles: [],
})
export class NewFolderDialogComponent implements OnInit {
  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}
  ngOnInit(): void {
    if (this.config?.data?.element) {
      this.folderName = this.config.data.element.name;
    }
  }

  folderName: string;

  onSave() {
    this.ref.close(this.folderName);
  }
}
