import { Component, Inject } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'renovars-new-file-dialog',
  template: `
    <fullstack-input-box [(ngModel)]="folderName" [options]="{ label: 'Nome file' }"></fullstack-input-box>
    <fullstack-file-upload-new
      [storageUrl]="env.endpoint + '/file-server/download'"
      [fileList]="files"
      (onFilesSelect)="onFilesSelect($event)"
      (onSaveChanges)="onSave()"
      [singleView]="true"
      [options]="{ label: 'File' }"
    >
    </fullstack-file-upload-new>
  `,
  styles: [],
})
export class NewFileDialogComponent {
  file_id: string;
  folderName: string;
  files: any[];
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    @Inject('FILE_M_CONF') public env: { endpoint: string },
  ) {}

  onSave() {
    if (this.file_id) {
      this.ref.close({
        name: this.folderName,
        file_id: this.file_id,
      });
    }
  }

  onFilesSelect(event) {
    /* const files: FileList = event.target.files;
    this.folderName = files.item(0).name;
    this.filesServ
      .uploadFiles(files)
      .pipe(
        filter((res) => res.length > 0 && res[0].length > 0),
        map((matrix) => matrix[0][0]),
        tap((value) => {
          this.file_id = value;
        }),
        mergeMap((id) => this.filesServ.update(id, { name: this.folderName })),
      )
      .subscribe(); */
  }
}
