<div class="h-100 font-sans bg-[#24292d]">
  <div class="flex items-center justify-center content-center min-h-screen min-w-screen overflow-hidden">
    <div class="flex flex-column items-center content-center">
      <div class="">
        <div class="text-center mb-5">
          <img src="assets/img/logo.png" alt="Image" height="50" class="mb-3" />
        </div>

        <form [formGroup]="loginForm">
          <label for="email1" class="block !text-gray-50 text-xl font-medium mb-2">Email</label>
          <input
            id="email1"
            type="text"
            formControlName="email"
            class="w-full mb-4"
            placeholder="Email"
            pInputText
            inputStyleClass="w-full p-4"
            styleClass="p-4"
          />

          <label for="password1" class="block !text-gray-50 font-medium text-xl mb-2">Password</label>
          <p-password
            id="password1"
            formControlName="password"
            placeholder="Password"
            [feedback]="false"
            [toggleMask]="true"
            styleClass="w-full mb-3"
            inputStyleClass="w-full"
          ></p-password>

          <div class="h-6"></div>
          <!-- <div class="flex flex-row-reverse items-center justify-content-between mb-5 gap-5">
                    <a
                      class="font-medium no-underline ml-2 text-right cursor-pointer"
                      style="color: var(--primary-color)"
                      >Password dimenticata?</a
                    >
                  </div> -->

          <div *ngIf="hasError" class="!text-red-500 font-bold my-4">Credenziali Errate</div>
          <button mat-raised-button class="py-4 w-full" type="submit" (click)="login()" color="accent">Accedi</button>
        </form>
      </div>
    </div>
  </div>

  <footer class="footer-login" id="footer">
    © 2024 Fullstack · v1.0.0-DEV.00 · By Mondo a Colori Media Network S.R.L. · PIVA 08965661005
  </footer>
</div>

