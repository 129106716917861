<fullstack-page-layout [registration]="(user$ | async)?.registrations">
  <div sidebar class="font-sans uppercase font-medium">
    <fullstack-sidebar-menu
      [options]="{
        logoUrl: 'assets/img/logo.png',
        logoMinUrl: 'assets/img/logo_min.png'
      }"
      [user]="$any(user$ | async)?.given_name + ' ' + $any(user$ | async)?.family_name"
      [userId]="$any(user$ | async)?.sub"
      (logout)="logout()"
    >
      <sidebar-item
        [label]="'Anagrafica'"
        [url]="'./contacts'"
        *permissions="['contact_manager']"
        [icon]="'fa-id-card'"
      ></sidebar-item>

      <sidebar-item [label]="'Leads'" [url]="'./project/sale/lead/list'"></sidebar-item>
      <sidebar-item [label]="'Opportunità'" [url]="'./project/sale/opportunity/list'"></sidebar-item>

      <sidebar-item
        [label]="'Appuntamenti'"
        [url]="'./appuntamento'"
        *permissions="['event_manager']"
        [icon]="'fa-calendar-alt'"
      ></sidebar-item>

      <sidebar-item
        [label]="'Calendario'"
        [url]="'./calendar'"
        *permissions="['event_manager']"
        [icon]="'fa-calendar-day'"
      ></sidebar-item>

      <sidebar-item
      [label]="'Network'"
      [url]="'./network'"
      *permissions="['network_manager', 'is_back_office']"
      [icon]="'fa-project-diagram'"
    ></sidebar-item>
      <sidebar-item
        [label]="'Utenti'"
        [url]="'./users/list'"
        *permissions="['user_manager', 'can_read_users_list', 'is_back_office']"
        [icon]="'fa-users'"
      >

      </sidebar-item>

      <sidebar-item
        [label]="'Impostazioni'"
        [url]="'./settings'"
        *permissions="['settings_manager']"
        [icon]="'fa-cogs'"
      >
        <sidebar-item [label]="'Documenti'" [url]="'documents'"></sidebar-item>
        <sidebar-item [label]="'Sedi'" [url]="'site'"></sidebar-item>
      </sidebar-item>
    </fullstack-sidebar-menu>
  </div>
  <div class="mainContent" content>
    <router-outlet></router-outlet>
  </div>
</fullstack-page-layout>
