import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationStart, Router } from '@angular/router';
import { UserPayloadEntity } from '@fi/app/client';
import { AuthServices } from '@fi/app/core/services/auth.services';
import { EventService } from '@fi/app/core/services/event.service';
import { BlockingStartupModalComponent } from '@fi/app/features/appointment/blocking-startup-modal/blocking-startup-modal.component';
import { Observable, filter, first, map, switchMap, tap } from 'rxjs';
import { environment } from './../../../../../environments/environment';

@Component({
  selector: 'home-page',
  templateUrl: `./home-page.component.html`,
  styles: [
    `
      .mainContent {
        height: 100%;
      }
    `,
  ],
})
export class HomePageComponent implements OnInit {
  user$: Observable<UserPayloadEntity>;
  environment = environment;
  meStatus = null;

  constructor(
    private authService: AuthServices,
    private eventService: EventService,
    private dialog: MatDialog,
    private router: Router,
  ) {
    this.initMenu();

    this.eventService
      .getMeStatus()
      .pipe(
        filter((meStatus) => meStatus !== null && meStatus.events && meStatus.events.length > 0),
        tap((meStatus) => (this.meStatus = meStatus)),
        switchMap(() => this.authService.user().pipe(first((user) => user !== null))),
      )
      .subscribe((user) => {
        this.openPendingEventsDialog(user);
        this.router.events.subscribe((navigation) => {
          if (navigation instanceof NavigationStart) {
            this.openPendingEventsDialog(user);
          }
        });
      });
  }

  openPendingEventsDialog(user) {
    //if (environment.production) {
    this.dialog.open(BlockingStartupModalComponent, {
      height: '80%',
      minWidth: '80vw',
      disableClose: true,
      enterAnimationDuration: '0ms',
      data: { events: null, user },
    });
    //}
  }

  initMenu() {}

  ngOnInit(): void {
    this.user$ = this.authService
      .user()
      .pipe(
        switchMap((u) =>
          this.authService.userMeta().pipe(map((value) => ({ ...u, registrations: value.registrations }))),
        ),
      );
  }

  logout() {
    this.authService.logout();
  }
}
