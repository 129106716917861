import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { UiModule } from '@fi/app/ui/ui.module';
import { ButtonModule } from 'primeng/button';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { MenuModule } from 'primeng/menu';
import { ToolbarModule } from 'primeng/toolbar';
import { CanBeViewedByPipe } from './can-be-viewed-by.pipe';
import { FileManagerHandlerComponent } from './file-manager-handler.component';
import { FileManagerComponent } from './file-manager.component';
import { FileMenuDialogComponent } from './file-menu-dialog.component';
import { NewFileDialogComponent } from './new-file-dialog.component';
import { NewFolderDialogComponent } from './new-folder-dialog.component';
import { SetVisibilityDialogComponent } from './set-visibility-dialog.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: FileManagerHandlerComponent,
      },
    ],
  },
];

@NgModule({
  declarations: [
    FileManagerHandlerComponent,
    FileManagerComponent,
    NewFolderDialogComponent,
    NewFileDialogComponent,
    FileMenuDialogComponent,
    SetVisibilityDialogComponent,
    CanBeViewedByPipe,
  ],
  imports: [
    CommonModule,
    DynamicDialogModule,
    ToolbarModule,
    ContextMenuModule,
    MenuModule,
    FormsModule,
    UiModule,
    ButtonModule,
    RouterModule.forChild(routes),
  ],
  providers: [],
})
export class FileManagerModule {
  static forRoot(config: { endpoint: string; roles: Record<string, string> }): ModuleWithProviders<FileManagerModule> {
    return {
      ngModule: FileManagerModule,
      providers: [
        {
          provide: 'FILE_M_CONF',
          useValue: config,
        },
      ],
    };
  }
}
