import { Component, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthServices } from '@fi/app/core/services/auth.services';
import { catchError, of } from 'rxjs';

@Component({
  selector: 'login-page',
  templateUrl: `login-page.component.html`,
  encapsulation: ViewEncapsulation.None,
})
export class LoginPageComponent {
  constructor(
    private authService: AuthServices
  ) {}

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
  });
  hasError= false;
  login() {

    if (this.loginForm.valid) {
      this.hasError = false;
      this.authService
        .login(this.loginForm.get('email').value, this.loginForm.get('password').value)
        .pipe(
          catchError((err) => {
            return of(
              this.hasError = true
            );
          }),
        )
        .subscribe();
    }
  }
}
