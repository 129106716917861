import { Injectable, NgZone } from '@angular/core';
import { environment } from '@fi/environment/environment';
import { Observable, map, switchMap, tap } from 'rxjs';
import { MessageService } from 'primeng/api';
import { EventPayload } from '@fi/app/core';
import { AuthServices } from './auth.services';
@Injectable()
export class ServerEventService {
  constructor(
    private _zone: NgZone,
    private auth: AuthServices,
    private message: MessageService,
  ) {}

  private connectClient(url) {
    return new Observable<string>((observer) => {
      const eventSource = new EventSource(url, {
        withCredentials: true,
      });
      eventSource.onmessage = (res) => {
        if (!res.data) return;
        this._zone.run(() => {
          observer.next(res.data);
        });
      };
      eventSource.onerror = (e) => {
        this._zone.run(() => {
          observer.error(e);
        });
      };
      return () => {
        eventSource.close();
      };
    });
  }

  subToServerEvents$() {
    return this.auth.user().pipe(
      switchMap((user) => this.connectClient(`${environment.apiURL}/server-events/notify/sse?id=${user.id}`)),
      map((v) => JSON.parse(v)),
      tap((val: EventPayload) => {
        if (val.kind === 'HTML') {
          this.message.add({
            severity: 'success',
            summary: val.data as string,
            sticky: true,
          });
        } else {
          this.message.add({
            severity: 'success',
            summary: val.data as string,
            sticky: true,
          });
        }
      }),
    );
  }
}
