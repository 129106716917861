import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthHomeGuard } from '@fi/app/core/guards/auth-home.guard';
import { AuthServices } from '@fi/app/core/services/auth.services';
import { AppResolver } from '../../core/resolver/app.resolver';
import { FeauturesSharedModule } from '../features-shared.module';
import { HomePageComponent } from './components/pages/home-page.component';
import { RoadmapComponent } from './components/roadmap/roadmap.component';
import { WelcomeComponent } from './components/welcome/welcome.component';

export const ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'home/welcome',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HomePageComponent,
    canActivate: [AuthHomeGuard],
    resolve: [AppResolver],
    children: [
      {
        path: 'welcome',
        component: WelcomeComponent,
      },
     /*  {
        path: 'dashboard',
        loadChildren: () => import('../dashboard/dashboard.module').then((m) => m.DashboardModule),
      }, */
      {
        path: 'contacts',
        loadChildren: () => import('../contacts/contact.module').then((m) => m.ContactModule),
      },
      {
        path: 'appuntamento',
        loadChildren: () => import('../appointment/appointment-wrap.module').then((m) => m.AppointmentWrapModule),
      },
     /*  {
        path: 'dispute',
        loadChildren: () => import('../dispute/dispute.module').then((m) => m.DisputeModule),
      }, */
      {
        path: 'calendar',
        loadChildren: () => import('../calendario/calendario.module').then((m) => m.CalendarioModule),
      },
      {
        path: 'project/sale',
        loadChildren: () => import('../sales/sales.module').then((m) => m.SalesModule),
      },
     /*  {
        path: 'project/rentout',
        loadChildren: () => import('../rentout/rentout.module').then((m) => m.RentoutModule),
      },
      {
        path: 'project/purchase',
        loadChildren: () => import('../purchases/purchases.module').then((m) => m.PurchasesModule),
      },
      {
        path: 'project/rent',
        loadChildren: () => import('../rent/rent.module').then((m) => m.RentModule),
      },
      {
        path: 'property',
        loadChildren: () => import('../property/property.module').then((m) => m.PropertyModule),
      },*/
      {
        path: 'network',
        loadChildren: () => import('../network/network.module').then((m) => m.NetworkModule),
      },
      {
        path: 'users',
        loadChildren: () => import('../utenti/utenti.module').then((m) => m.UtentiModule),
      },
      {
        path: 'profile',
        loadChildren: () => import('../profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'settings',
        loadChildren: () => import('./../settings/settings.module').then((m) => m.SettingsModule),
      },
      /* {
        path: 'file-manager',
        loadChildren: () => import('../file-manager/file-manager.module').then((m) => m.FileManagerModule),
      },
      {
        path: 'roadmap',
        component: RoadmapComponent,
      }, */
    ],
  },
];

@NgModule({
  declarations: [HomePageComponent, RoadmapComponent],
  exports: [],
  imports: [RouterModule.forChild(ROUTES), FeauturesSharedModule],
  providers: [AppResolver, AuthServices, AuthHomeGuard],
})
export class HomeModule {}
