<p-toolbar>
  <div class="p-toolbar-group-left">
    <i class="pi pi-arrow-left" (click)="navigateUp()"></i>
    <span style="margin-left: 8px">{{ path || 'Files' }}</span>
  </div>

  <div class="p-toolbar-group-right" *ngIf="canAddFileOrFolder">
    <i class="pi pi-folder-open me-2" (click)="openNewFolderDialog()"></i>
    <i class="pi pi-file" (click)="openNewFileDialog()"></i>
  </div>
</p-toolbar>
<p-contextMenu #cm [model]="items" [target]="$any(item)"></p-contextMenu>
<div class="row mx-2 my-4" *ngFor="let element of fileElements">
  <ng-container *ngIf="element?.canBeViewedBy | canBeViewedBy: activeUser">
    <div #item (contextmenu)="handleMenu($event); setSelected(element)">
      <i [ngClass]="element.isFolder ? 'pi pi-folder' : 'pi pi-file'" (click)="navigate(element)"></i>
      {{ element.name }}
    </div>
  </ng-container>
  <div class="w-full"></div>
</div>
