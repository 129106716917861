import { Component, OnInit } from '@angular/core';
import { FileManagerEntity, FileManagerService } from '@fi/app/client';
import { IFileElement } from '@fi/app/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'renovars-file-manager-handler',
  templateUrl: './file-manager-handler.component.html',
  styles: [],
})
export class FileManagerHandlerComponent implements OnInit {
  public fileElements: Observable<FileManagerEntity[]>;

  constructor(public fileManagerService: FileManagerService) {}

  currentRoot: FileManagerEntity;
  currentPath: string;
  canNavigateUp = false;

  ngOnInit() {
    this.updateFileElementQuery();
  }

  addFolder(folder: { name: string }) {
    this.fileManagerService
      .create({
        isFolder: true,
        name: folder.name,
        parent: this.currentRoot ? this.currentRoot._id : 'root',
        canBeViewedBy: [],
      })
      .subscribe(() => this.updateFileElementQuery());
  }

  addFile(file: { name: string; file_id: string }) {
    this.fileManagerService
      .create({
        isFolder: false,
        name: file.name,
        file_id: file.file_id,
        parent: this.currentRoot ? this.currentRoot._id : 'root',
        canBeViewedBy: [],
      })
      .subscribe(() => this.updateFileElementQuery());
  }

  removeElement(element: IFileElement) {
    this.fileManagerService.remove(element._id).subscribe(() => this.updateFileElementQuery());
  }

  navigateToFolder(element: IFileElement) {
    this.currentRoot = element;
    this.updateFileElementQuery();
    this.currentPath = this.pushToPath(this.currentPath, element.name);
    this.canNavigateUp = true;
  }

  navigateUp() {
    if (this.currentRoot && this.currentRoot.parent === 'root') {
      this.currentRoot = null;
      this.canNavigateUp = false;
      this.updateFileElementQuery();
    } else {
      this.fileManagerService.findOne(this.currentRoot.parent).subscribe((v) => (this.currentRoot = v));
      this.updateFileElementQuery();
    }
    this.currentPath = this.popFromPath(this.currentPath);
  }

  moveElement(event: { element: IFileElement; moveTo: IFileElement }) {
    this.fileManagerService.update(event.element._id, { parent: event.moveTo._id });
    this.updateFileElementQuery();
  }

  renameElement(element: IFileElement) {
    this.fileManagerService.update(element._id, { name: element.name }).subscribe(() => this.updateFileElementQuery());
  }

  updateFileElementQuery() {
    this.fileElements = this.fileManagerService.findByParent(this.currentRoot ? this.currentRoot._id : 'root');
  }

  pushToPath(path: string, folderName: string) {
    let p = path ? path : '';
    p += `${folderName}/`;
    return p;
  }

  popFromPath(path: string) {
    let p = path ? path : '';
    const split = p.split('/');
    split.splice(split.length - 2, 1);
    p = split.join('/');
    return p;
  }
  setViewRole(element: IFileElement) {
    this.fileManagerService.update(element._id, { canBeViewedBy: element.canBeViewedBy }).subscribe();
  }
}
