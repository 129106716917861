import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IRealitycsAnalysis, ProjectsSaleService } from '../../client';

@Injectable()
export class RealitycsService {
  constructor(private projectsSaleService: ProjectsSaleService) {}

  get(id: string): Observable<IRealitycsAnalysis> {
    return this.projectsSaleService.getRealitycsAllPropertyInfo(id);
  }

  post(province: string, totalRooms: number) {
    return this.projectsSaleService.getSoldProperties({ province, totalRooms });
  }
}
