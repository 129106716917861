import { Injectable } from '@angular/core';
import { CallcenterDashboardType, IMarketingLeadDashboardQuery, SourceTypeDomainValueType } from '@fi/app/core';
import { Observable } from 'rxjs';
import {
  CallcenterPurchaseDashboardDataDto,
  CallcenterSellingDashboardDataDto,
  DashboardService,
  DashboardSummaryRequestDto,
  DashboardSummaryResponseDto,
  OfficeControlDashboardDto,
  ScoreDashboardDto,
} from '../../client';

const ROUTE = 'dashboard';

@Injectable()
export class DashboardSharedService {
  constructor(private dashboardService: DashboardService) {}

  summaryDashboard(params: DashboardSummaryRequestDto): Observable<DashboardSummaryResponseDto> {
    return this.dashboardService.summaryDashboard(params);
  }

  marketingDashboard(params: {
    start: Date;
    end: Date;
    type?: SourceTypeDomainValueType;
  }): Observable<OfficeControlDashboardDto> {
    return this.dashboardService.marketingDashboard(params.start.toISOString(), params.end.toISOString(), params.type);
  }

  officeControlDashboard(params: {
    storeManagerId: string;
    start: Date;
    end: Date;
    type?: SourceTypeDomainValueType;
  }): Observable<OfficeControlDashboardDto> {
    return this.dashboardService.officeControlDashboard(
      params.start.toISOString(),
      params.end.toISOString(),
      params.type,
    );
  }

  scoreDashboard(params: { start: Date; end: Date }): Observable<ScoreDashboardDto> {
    return this.dashboardService.scoreDashboard(params.start.toISOString(), params.end.toISOString());
  }

  callcenterSellingDashboard(params: {
    type?: CallcenterDashboardType;
    userId: string;
    start: Date;
    end: Date;
  }): Observable<CallcenterSellingDashboardDataDto> {
    return this.dashboardService.callcenterSellingDashboard(
      params.type,
      params.start.toISOString(),
      params.end.toISOString(),
    );
  }

  callcenterPurchaseDashboard(params: {
    type?: CallcenterDashboardType;
    userId: string;
    start: Date;
    end: Date;
  }): Observable<CallcenterPurchaseDashboardDataDto> {
    return this.dashboardService.callcenterPurchaseDashboard(
      params.type,
      params.start.toISOString(),
      params.end.toISOString(),
    );
  }

  getTotalMarketingLead(filter: IMarketingLeadDashboardQuery) {
    return this.dashboardService.marketingLeadDashboard(
      filter.code,
      filter.start.toISOString(),
      filter.end.toISOString(),
      filter.network,
    );
  }
}
